<script setup lang="ts">
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseMdl, { ModalWidth } from '/~/components/mdl/mdl-popup.vue'
import { useConfirmComponent } from '/~/modals/confirm/confirm'

const props = withDefaults(
  defineProps<{
    title: string
    description: string
    descriptionWhitespace?: boolean
    showConfirm?: boolean
    showCancel?: boolean
    confirmLabel?: string
    cancelLabel?: string
    width?: ModalWidth
    headerBorderBottom?: boolean
    showCloseIcon?: boolean
    onConfirm?: () => Promise<void>
    onCancel?: () => Promise<void>
  }>(),
  {
    confirmLabel: 'Confirm',
    cancelLabel: 'Cancel',
    showCloseIcon: true,
    showConfirm: true,
    showCancel: true,
    headerBorderBottom: false,
    width: 'sme',
    descriptionWhitespace: true,
  }
)

const emit = defineEmits<{
  (event: 'hide'): void
}>()

const { confirming, cancelling, loading, onConfirm, onCancel } =
  useConfirmComponent({
    onConfirm: props.onConfirm,
    onCancel: props.onCancel,
    hide,
  })

function hide() {
  emit('hide')
  modal.hide()
}
</script>

<template>
  <base-mdl
    :width="width"
    :closable="!loading"
    :show-close-icon="showCloseIcon"
    :title="title"
    :header-border-bottom="headerBorderBottom"
    layout="v2"
    padding="xs"
  >
    <div class="-mx-5 border-y p-5">
      <div
        :class="[descriptionWhitespace && 'whitespace-pre-line']"
        v-html="description"
      />
    </div>

    <div
      v-if="showConfirm || showCancel"
      class="mt-5 grid"
      :class="{ 'grid-cols-2 gap-4': showConfirm && showCancel }"
    >
      <base-button
        v-if="showCancel"
        v-analytics:click="{
          cta: 'Cancel',
          pageGroup: 'Confirm Modal',
          page: title,
        }"
        :look="showConfirm ? 'outlined-color' : 'filled'"
        :disabled="loading"
        :loading="cancelling"
        class="!rounded"
        @click="onCancel"
      >
        {{ cancelLabel }}
      </base-button>
      <base-button
        v-if="showConfirm"
        v-analytics:click="{
          cta: 'Confirm',
          pageGroup: 'Confirm Modal',
          page: title,
        }"
        :disabled="loading"
        :loading="confirming"
        class="!rounded-sm"
        full-width
        @click="onConfirm"
      >
        {{ confirmLabel }}
      </base-button>
    </div>
  </base-mdl>
</template>
